.body_bg {
    background: url(../assests/body_bg.jpg)no-repeat center;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
}
.batman{
       background: #00000094;
   }
   .verify{
color: #fff;
   }
#particles-js {
    position: fixed;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100% !important;
}
.banner_inner {
    padding: 60px 0px;
}
.banner_logo {
    width: 600px;
    margin: auto;
    display: block;
}
.text-center {
    text-align: center!important;
}
.banner_inner p {
    font-size: 18px;
    max-width: 750px;
    margin: auto;
    text-align: center;
    margin-top: 40px;
}
.video-icon {
    margin: auto;
    display: inline-block;
    margin: 3px 7px;
    width: 60px;
    margin-top: 30px;
}
.pb_30 {
    padding-bottom: 20px;
}
.cus_row {
    margin: 0px -5px;
}
.cus_row .col-6 {
    padding: 0px 5px;
}
.Personal_Details_inner {
    background: #a57e25;
    color: #ffffff;
    border-top: 3px solid #fff;
    margin: 5px 0px;
    text-align: center;
    padding: 30px 5px;
    position: relative;
}
.buyaw{
    cursor:pointer;
}
.Personal_Details_inner:hover {
    background: #fff;
    color: #a57e25;
    border-top: 3px solid #a57e25;
}
.Personal_Details_inner img {
    filter: brightness(0) invert(1);
    width: 50px;
    margin-bottom: 15px;
}
.Personal_Details_inner:hover img{
    filter: none;
    
}
.Personal_Details_inner h4 {
    font-size: 14px;
    text-transform: uppercase;
}
.Personal_Details_inner h5 {
    margin-bottom: 0px;
    font-size: 16px;
}
.sm_container {
    max-width: 700px;
    padding: 50px 0px;
    position: relative;
    margin: auto;
}
.sm_container_bg {
    position: relative;
    background: #0000007a;
    margin-bottom: 20px;
    border-radius: 45px;
}
.sm_container_padding {
    padding: 40px 30px 30px 30px;
}
.all_heading {
    margin-bottom: 20px;
}
.all_heading h3 {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 22px;
    font-weight: 500;
    background: -webkit-linear-gradient(top, #fff 45%, #b48932 48%, #ffb73b 85%);
    background-clip: border-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.mb_20 {
    margin-bottom: 20px;
}
.form-group {
    margin-bottom: 1rem;
}
.white_label {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}
.cus_input {
    background: #fff;
    color: #333;
    border: none;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
}
.trx_btn {
    margin: 0px -3px;
    overflow: hidden;
    list-style: none;
}
.trx_btn button:hover {
    background-color: #b48932;
    color: #fff;
}
.trx_btn button:focus {
    background-color: #b48932;
    color: #fff;
}
.trx_btn li {
    display: block;
    float: left;
    width: 14.284%;
    padding: 5px 3px;
}
.trx_btn li button {
    display: block;
    width: 100%;
    border: none;
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
    background: #fff;
}
.text-center {
    text-align: center!important;
}
.grad_btn {
    background: linear-gradient(to right,#a57e25,#d8a451);
    box-shadow: 0 5px 40px rgb(189 144 57 / 36%);
    color: #fff;
    letter-spacing: 2px;
    border: none;
    cursor: pointer;
    padding: 2px 25px 0;
    height: 45px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.grad_btn:hover{
     background: linear-gradient(to left,#a57e25,#d8a451);

}
.sm_container::before {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 30px;
    height: 51px;
    content: '';
    background: url(../assests/block_top.png) no-repeat center top / 100%;
}
.sm_container::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 52px;
    height: 43px;
    content: '';
    background: url(../assests/block_bottom.png) no-repeat center bottom / 100%;
}
.pb_60 {
    padding-bottom: 60px;
}
.small_heading {
    width: 100%;
}
.small_heading h5 {
    color: #fff;
}


.word-break {
    word-wrap: break-word;
}
.word-break {
    word-break: break-all;
}
.referal_text {
    color: #d8a451;
    text-align: center;
    font-size: 17px;
    letter-spacing: 3px;
}
.mt_20 {
    margin-top: 20px;
}
.mb_40 {
    margin-bottom: 40px;
}
.pdf_downlad_ul {
    list-style: none;
    overflow: hidden;
    margin-bottom: 0px;
    text-decoration: none;

}
.pdf_downlad_ul li {
    display: block;
    float: left;
    width: 33.33%;
    padding: 0px 7px;
    text-decoration: none;
}
.pdf_downlad_ul li a {
    display: block;
    margin: 10px 0px;
    padding-left: 80px !important;
    border: 1px solid #fff;
    position: relative;
    padding: 15px 10px;
      text-decoration: none;
}
.pdf_downlad_ul li a span {
    display: block;
    border-right: 1px solid #fff;
    flex-direction: column;
    display: -webkit-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    font-size: 30px;
    text-align: center;
    width: 65px;
    color: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    padding: 7px;
}
.pdf_downlad_ul li a h5 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0px;
}
.pdf_downlad_ul li a p {
    font-size: 13px;
    color: #d8a451;
    margin-bottom: 0px;
    margin-top: 3px;
}
.md_container {
    max-width: 900px;
    margin: 20px auto;
    background: #0000007a;
    padding: 30px 30px;
}

.footer1 {
    background: #0000007a;
    text-align: center;
    padding: 40px 0px;
    color: #fff;
}

.pdf_downlad_ul span:hover{
     /* background: -webkit-linear-gradient(top, #fff 45%, #b48932 48%, #d09e4a 85%); */
    background-color:  #b48932;
}
.mdf{
    background-image: -webkit-linear-gradient(top, #fff2d7 50%, #b489327c 50%, #d09f4a 50%);
     background-clip: text;
  color: transparent;
  text-align: center;
  font-size: 50px;
  font-weight: 750;
  animation: glow 1s ease-in-out infinite alternate;
}
.table thead th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: #a57e25;
    color: #fff;
    padding: 20px;
}
.table-bordered td, .table-bordered th {
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    color: #fff;
}
.table {
    color: #fff;
}
.aw_td{
    width: 120px;
   
}
.aw_td1{
    width: 50%;
}
.pm_21:hover{
    background: #a57e25;
}
@media only screen and (max-width: 760px){
.pdf_downlad_ul li{
    width: 100%;
}

.trx_btn li {
    width: 33.33%;
}
.pb_60 {
    
    padding: 10px;
}
.pb_30 {
   
    padding: 10px;
}
}

